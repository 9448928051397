import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { Button } from "antd";
import { message } from "components/UI/Messages";

import useHttpErrorHandler from "hooks/useHttpErrorHandler";
import { AuthContext } from "components/Auth/useAuth";

function TimeoutMessage({ customErrorMessage }) {
  return (
    <span>
      {customErrorMessage || "Error de conexión o tiempo expirado"}
      <Button type="link" onClick={() => window.location.reload()}>
        Recargar Página
      </Button>
    </span>
  );
}

function NotAuthenticatedMessage({ onClick }) {
  return (
    <span>
      Usuario no autenticado. Espere unos segundos o haga click aquí:
      <Button type="link" onClick={onClick}>
        Iniciar Sesión
      </Button>
    </span>
  );
}

function handleError(error, { history, match }, resetStateOnLogout) {
  const {
    serverError,
    timeoutOrConnection,
    forbidden,
    notAuthenticated,
    customErrorMessage
  } = error;

  if (serverError) {
    history.push({ pathname: "/error" });
  } else if (forbidden) {
    history.push({ pathname: "/forbidden403" });
  } else if (notAuthenticated) {
    if (match.path.includes("login")) {
      resetStateOnLogout();
    } else {
      const resetAndRedirect = () => {
        resetStateOnLogout();
        history.push({ pathname: "/login" });
      };
      message
        .error(<NotAuthenticatedMessage onClick={resetAndRedirect} />, 2)
        .then(resetAndRedirect);
    }
  } else if (timeoutOrConnection) {
    message.error(
      <TimeoutMessage customErrorMessage={customErrorMessage} />,
      5
    );
  }
}

// eslint-disable-next-line import/prefer-default-export
export function withErrorCheck(WrappedComponent) {
  return withRouter(props => {
    const { history, match } = props;
    const [error, errorConfirmedHandler] = useHttpErrorHandler(axios);
    const { resetStateOnLogout } = React.useContext(AuthContext);

    React.useEffect(() => {
      errorConfirmedHandler();
    }, [errorConfirmedHandler]);

    if (error !== null) {
      handleError(error, { history, match }, resetStateOnLogout);
    }

    return <WrappedComponent {...props} />;
  });
}
