import styled from "styled-components";

const StyledSVGIcon = styled.svg`
  width: 1.2em;
  height: 1.2em;

  path,
  polygon,
  rect {
    fill: #f6993f;
  }

  circle {
    stroke: #f6993f;
    stroke-width: 1;
  }
`;

export default StyledSVGIcon;
