export const DEFAULT_LOCALE = "en-GB";
export const PARAGUAY_LOCALE = "es-PY";

export function dateFormatter(params) {
  const { value = null } = params;
  if (!value) {
    return "";
  }
  return formatDate(value);
}

export function formatDate(isoFormattedDateStr) {
  if (isoFormattedDateStr) {
    const date = new Date(isoFormattedDateStr);
    const formated = new Intl.DateTimeFormat(DEFAULT_LOCALE).format(date);
    return formated;
  }
  return null;
}

export function dateTimeFormatter(params) {
  const { value } = params;
  return value ? formatDateTime(value) : "";
}

export function formatDateTime(
  isoFormattedDateStr = null,
  locale = DEFAULT_LOCALE,
  options = {}
) {
  if (isoFormattedDateStr == null) {
    return null;
  }
  const date = new Date(isoFormattedDateStr);
  return date.toLocaleString(locale, options);
}

export function getTimeFormatter(moment) {
  return function timeFormatter(params) {
    const { value } = params;
    return value && moment(value).format("HH:mm");
  };
}

export function formatMonthAndYear(date) {
  if (date != null) {
    return date.locale("es").format("MMMM  YYYY");
  }
  return null;
}

export function monthFormatter(date) {
  if (date != null) {
    const dateFormat = new Date(date);
    const month = dateFormat.toLocaleString("es-ES", { month: "long" });
    return month;
  }
  return null;
}

export function isInTheFuture(currentDate, moment) {
  return currentDate.isAfter(moment().endOf("day"));
}

export function isDateFromToday(isoDate) {
  const inputDate = new Date(isoDate);
  const today = new Date();
  const isDateSame = inputDate.getUTCDate() === today.getDate();
  const isMonthSame = inputDate.getUTCMonth() === today.getMonth();
  const isYearSame = inputDate.getUTCFullYear() === today.getFullYear();
  return isDateSame && isMonthSame && isYearSame;
}
