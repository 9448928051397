import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Card, Collapse } from "antd";
import Divider from "antd/lib/divider";
import Alert from "antd/lib/alert";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { getArticleInfo } from "api/publicApi";
import lincolnLogo from "../../images/lincoln_logo.png";
import { formatToPYG } from "../../utils/numberFormat";

const ArticlePublicDetail = props => {
  const { param } = props;
  const [article, setArticle] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getArticleInfo(param)
      .then(({ response }) => {
        setArticle(response);
        setError(null);
      })
      .catch(err => {
        setArticle(null);
        setError(err);
      });
  }, [param]);

  return <ArticleDetailView details={{ article, error }} />;
};

function ArticleDetailView(props) {
  const { article, error } = props.details;
  const { Panel } = Collapse;
  return (
    <div className="info-container">
      {error !== null && (
        <Alert
          message={<span>No se encontró el artículo solicitado</span>}
          type="warning"
        />
      )}
      {error === null && article == null && (
        <Alert
          message={
            <span>
              Hubo un error inesperado en la consulta. Contacte al servicio.
            </span>
          }
          type="error"
        />
      )}
      {article != null && (
        <div>
          {/** *****************************Section 1****************************** */}
          <Row>
            <Col span={6}>
              <img className="logo" src={lincolnLogo} alt="Lincoln S.A. logo" />
            </Col>
          </Row>
          {(article.imageLink1 || article.imageLink2 || article.imageLink3) && (
            <>
              <Divider />
              <div className="img-container">
                <ArticleImageView article={article} />
              </div>
            </>
          )}

          <Divider />
          {/** ***********************************Section 2********************************** */}
          <div className="secondary">
            <span className="info-code">
              Código del Producto {article.code}
            </span>
            <br />
            <span className="info-name">{article.name}</span>
            <br />
            <span className="info-value">
              Precio: Gs. {formatToPYG(article.priceStoreGs)}
            </span>
            {article.detail && (
              <>
                <Collapse className="span-detail-title" ghost>
                  <Panel header="Detalles" key="details">
                    <span className="span-detail-text">{article.detail}</span>
                  </Panel>
                </Collapse>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function ArticleImageView({ article }) {
  const images = [
    { image: article.imageLink1 },
    { image: article.imageLink2 },
    { image: article.imageLink3 }
  ];

  const imagesItems = images
    .filter(({ image }) => image !== null)
    .map(({ image }) => (
      <Card
        className="img-zoom"
        hoverable
        cover={<img alt={article.name} src={image} />}
      />
    ));

  return imagesItems;
}

export default ArticlePublicDetail;
