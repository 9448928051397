// Inspiración de este componente:
// https://stackoverflow.com/questions/45354886/using-react-context-to-maintain-user-state

import React from "react";
import jwtDecode from "jwt-decode";
import { ACCESS_TOKEN_KEY, findOne } from "../../utils";
import authService from "../../api/authService";
import { getRoleName } from "./auth.utils";

const ANONYMOUS = "anonymous";

export const defaultInitialUser = {
  username: "anonymous",
  hasAnyRole: () => false,
  adminOrHasRole: () => false
};

export const ROLES = {
  ADMIN: { name: "ADMIN" },
  ENCARGADO_DEPOSITO: {
    name: "ENCARGADO_DEPOSITO",
    routes: "/orderconsolidation"
  },
  SECTOR_EMPAQUE_DEPOSITO: {
    name: "SECTOR_EMPAQUE_DEPOSITO",
    routes: "/orderpacking"
  },
  SECTOR_PREPARACION_DEPOSITO: {
    name: "SECTOR_PREPARACION_DEPOSITO",
    routes: "/orderprep"
  },
  ENCARGADO_CONSOLIDACION: {
    name: "ENCARGADO_CONSOLIDACION",
    routes: "/orderconsolidation"
  },
  ENCARGADO_FACTURACION: {
    name: "ENCARGADO_FACTURACION",
    routes: "/order"
  },
  VENDEDOR_EXTERNO: { name: "VENDEDOR_EXTERNO" },
  ENCARGADO_CTA_CORRIENTE: {
    name: "ENCARGADO_CTA_CORRIENTE",
    routes: "/client"
  },
  ENCARGADO_COMERCIAL: {
    name: "ENCARGADO_COMERCIAL",
    routes: "/salesman"
  },
  GERENTE_GENERAL: {
    name: "GERENTE_GENERAL",
    routes: "/salesman"
  },
  ENCARGADO_COBRANZAS: {
    name: "ENCARGADO_COBRANZAS",
    routes: "/uvm/payment"
  },
  OPERADOR_EMBARQUE: {
    name: "OPERADOR_EMBARQUE",
    routes: "/uvm/payment"
  },
  GERENTE_COMERCIAL: {
    name: "GERENTE_COMERCIAL",
    routes: "/uvm/proformas"
  },
  GERENTE_VENTAS: {
    name: "GERENTE_VENTAS",
    routes: "/uvm/proformas"
  },
  ASISTENTE_ADMINISTRATIVO: {
    name: "ASISTENTE_ADMINISTRATIVO",
    routes: "/uvm/payment"
  },

  ENCARGADO_DEVOLUCION: {
    name: "ENCARGADO_DEVOLUCION"
  },

  ENCARGADO_MARCAS: {
    name: "ENCARGADO_MARCAS"
  },
  CONTABILIDAD: {
    name: "CONTABILIDAD"
  },
  SISTEMA: {
    name: "SISTEMA"
  },
  DEPOSITO: {
    name: "DEPOSITO"
  },
  OPERADOR_ADMINISTRATIVO: {
    name: "OPERADOR_ADMINISTRATIVO"
  },
  OPERADOR_ECOMMERCE: {
    name: "OPERADOR_ECOMMERCE"
  },
  CAJA: {
    name: "CAJA"
  },
  VENTAS: {
    name: "VENTAS"
  },
  OPERADOR_VENTAS: {
    name: "OPERADOR_VENTAS"
  },
  OPERADOR_ARTICULOS: {
    name: "OPERADOR_ARTICULOS"
  },
  VENTAS_ECOMMERCE: {
    name: "VENTAS_ECOMMERCE"
  },
  ENCARGADO_REPOSICION: {
    name: "ENCARGADO_REPOSICION"
  },
  ENCARGADO_RRHH: {
    name: "ENCARGADO_RRHH"
  },
  AUDITOR: {
    name: "AUDITOR"
  },
  SUPERVISOR_VENTAS: {
    name: "SUPERVISOR_VENTAS",
    routes: "/client"
  }
};

export function buildUser(userProfile = {}) {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token === null) {
    return { username: ANONYMOUS, isAuthenticated: () => false };
  }
  const userInfo = jwtDecode(token);
  const { username, ...restUserProfile } = userProfile;
  return {
    username: username || userInfo.sub,
    ...restUserProfile,
    hasAnyRole(...roles) {
      const roleNames = roles.map(r => getRoleName(r));
      return userInfo && findOne(userInfo.joko.roles, roleNames);
    },
    hasAnyRoleDistinct(...roles) {
      const roleNames = roles.map(r => getRoleName(r));
      const userRoles = userInfo && userInfo.joko && userInfo.joko.roles;
      return (
        userRoles &&
        userRoles.some(role => roleNames.indexOf(getRoleName(role)) === -1)
      );
    },
    hasAnyRoleInList(roleList) {
      const roleNames = roleList.map(r => getRoleName(r));
      return userInfo && findOne(userInfo.joko.roles, roleNames);
    },
    adminOrHasRole(...roles) {
      return this.hasAnyRole(ROLES.ADMIN, ...roles);
    },
    isAdmin() {
      return this.hasAnyRole(ROLES.ADMIN);
    },
    isAuthenticated: () => true
  };
}

const unsubscribe = subscriber => {
  const index = subscribers.findIndex(subscriber);
  if (index >= 0) {
    subscribers.splice(index, 1);
  }
};

const subscribe = subscriber => {
  subscribers.push(subscriber);
  return () => unsubscribe(subscriber);
};

export const isAuthenticated = () =>
  localStorage.getItem(ACCESS_TOKEN_KEY) !== null;

const subscribers = [];

let state = {
  user: buildUser(),
  isAuthenticated
};

// eslint-disable-next-line no-unused-vars
const withAuth = Component => {
  return class WithAuth extends React.Component {
    _isMounted = false;

    componentDidMount() {
      // FIXME, this is called on logout,
      // maybe because of the Header rerender?
      // Header and Layout has withAuth
      this._isMounted = true;
      this.unsubscribe = subscribe(() => {
        this.forceUpdate();
      });
    }

    render() {
      const newProps = { ...this.props, auth: state };
      return <Component {...newProps} />;
    }

    componentWillUnmoount() {
      // FIXME, this is never called on logout
      this._isMounted = false;
      this.unsubscribe();
    }
  };
};

export const setTokens = ({ accessToken }) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const removeAccessToken = () => {
  return localStorage.removeItem(ACCESS_TOKEN_KEY);
};

// eslint-disable-next-line no-unused-vars
const logout = () => {
  return authService
    .signout()
    .then(() => {
      updateAuthStateOnLogout();
    })
    .catch(error => {
      console.error("error login out", { error });
      if (error.response) {
        const { response } = error;
        if (response.status === 401 || response.status === 403) {
          updateAuthStateOnLogout();
        }
      }
      return Promise.reject(error);
    });
};

const updateAuthStateOnLogout = () => {
  removeAccessToken();
  state = { ...state, user: null };
  subscribers.forEach(subscriber => subscriber());
};

// eslint-disable-next-line no-unused-vars
const updateAuthStateOnLogin = () => {
  state = { ...state, user: buildUser() };
  subscribers.forEach(subscriber => subscriber());
};

export default {
  setTokens,
  getAccessToken,
  removeAccessToken,
  isAuthenticated
};
