import React from "react";
import styled from "styled-components";
import packageJSON from "../../../../package.json";

const ENVIRONMENT = process.env.REACT_APP_STAGE;

const StyledVersion = styled.span`
  font-size: 11.5px;
  color: #848484d1;
`;

const Version = props => {
  return (
    ENVIRONMENT !== "production" && (
      <StyledVersion {...props}>
        Version Web {packageJSON.version} (be n/a)
      </StyledVersion>
    )
  );
};

export default Version;
