import React, { Component } from "react";
import LoginForm from "../../components/Login/Form";

// eslint-disable-next-line react/prefer-stateless-function
class ScreensLogin extends Component {
  render() {
    return (
      <div className="login-container">
        <LoginForm {...this.props} />
      </div>
    );
  }
}

export default ScreensLogin;
