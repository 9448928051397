import { FILTER_TYPE } from "../utils";

function getOperator(type) {
  if (type === FILTER_TYPE.CONTAINS) {
    return "LIKE";
  }
  return null;
}

function getConditions(filterModel) {
  const conditions = Object.keys(filterModel).map(key => ({
    filterValue: filterModel[key].filter,
    columnName: key,
    operator: getOperator(filterModel[key].type)
  }));

  return {
    conditions
  };
}

export function handleApiError(error) {
  console.error("save order error", { error });
  return Promise.reject(error);
}

export function handleApiResponse(json) {
  return getResponseOrReject(json);
}

function getResponseOrReject(json) {
  const { data } = json;
  if (!data.success) {
    return Promise.reject(data);
  }
  return data;
}

export function fromGridToSearchRequest({
  currentPage,
  pageSize,
  filterModel
}) {
  const pageToFetch = Object.keys(filterModel).length > 0 ? 0 : currentPage - 1;
  return {
    pagination: {
      currentPage: pageToFetch,
      pageSize
    },
    specifications: getConditions(filterModel)
  };
}

export function fromGridToFilterParams(gridParams) {
  return {
    ...gridParams,
    page: gridParams.currentPage,
    size: gridParams.pageSize
  };
}

export const getGridFilterTextEqual = (prop, value) => {
  return {
    [prop]: {
      filter: value,
      type: "equal",
      filterType: "text"
    }
  };
};

export const getGridFilterTextNotEqual = (prop, value) => {
  return {
    [prop]: {
      filter: value,
      type: "notEqual",
      filterType: "text"
    }
  };
};

export const getGridFilterTextLike = (prop, value) => {
  return {
    [prop]: {
      filter: value,
      type: "LIKE",
      filterType: "text"
    }
  };
};

export const getGridFilterTextContains = (prop, value) => {
  return {
    [prop]: {
      filter: value,
      type: "contains",
      filterType: "text"
    }
  };
};

export const getGridFilterNumberEqual = (prop, value) => {
  return {
    [prop]: {
      filter: value,
      type: "equal",
      filterType: "number"
    }
  };
};

export default {
  handleApiResponse,
  handleApiError
};
