import axios from "axios";
import { API_URL, REFRESH_TOKEN_KEY } from "../utils/constants";
import { handleApiResponse } from "./helpers";

export function requestPasswordRecovery(data) {
  return axios
    .post(`${API_URL}/passwords/recoveries`, data)
    .then(handleApiResponse);
}

export function updatePasswordByRecovery(token, dto) {
  return axios
    .put(`${API_URL}/passwords/recoveries/${token}`, dto)
    .then(handleApiResponse);
}

const auth = {
  authenticate({ username, password }) {
    return axios
      .post(`${API_URL}/login`, { username, password })
      .then(resp => {
        const { data } = resp;
        if (!data.success) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ data });
        }
        localStorage.setItem(REFRESH_TOKEN_KEY, data.secret);
        return this.userAccess(data.secret);
      })
      .catch(error => {
        console.error("Error during /login", { error });
        return Promise.reject(error);
      });
  },
  userAccess(refreshToken) {
    const authAxios = axios.create({
      headers: { "X-JOKO-AUTH": refreshToken }
    });
    return authAxios
      .post(`${API_URL}/token/user-access`)
      .then(resp => {
        const { data } = resp;
        if (!data.success) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ data });
        }
        return resp;
      })
      .catch(error => {
        console.error("Error during /user-access", { error });
        return Promise.reject(error);
      });
  },
  signout() {
    // Por algun motivo el /logout solamente
    // se puede invocar con un refresh token.
    const authAxios = axios.create({
      headers: { "X-JOKO-AUTH": localStorage.getItem(REFRESH_TOKEN_KEY) }
    });
    return authAxios.post(`${API_URL}/logout`).then(resp => {
      const { data } = resp;
      if (!data.success) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ data });
      }
      return resp;
    });
  },
  tokenInfo(accessToken) {
    return axios
      .get(`${API_URL}/token/info`, { params: { accessToken } })
      .then(resp => {
        const { data: tokenInfo } = resp;
        return tokenInfo;
      });
  },
  handleError(error) {
    if (error.response) {
      const { response } = error;
      if (response.status === 401 || response.status === 403) {
        this.removeToken();
      }
    }
    return Promise.reject(error);
  }
};

export default auth;
