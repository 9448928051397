/* eslint-disable react/jsx-no-bind */
import React, { Component } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import Layout from "antd/lib/layout";

import "antd/lib/layout/style/css";

import { AuthContext } from "components/Auth/useAuth";
import MainHeader from "./Header";
import SideMenu from "./SideMenu";
import Version from "../../components/UI/Version";
import MobileLayout from "./MobileLayout";

const { Header, Sider, Content } = Layout;

const ContentStyle = styled(Content)`
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  border-top: var(--page-section-border);
  box-shadow: var(--page-section-shadow);
  margin-top: 22px;
  margin-bottom: 22px;
  margin-right: 22px;
  padding: 2.5rem;
  background: #fff;
`;

const LayoutStyle = styled.div`
  background: var(--background-color);
  .ant-layout-sider-children {
    background: var(--background-color);
  }
`;

function getInitialSideNavState() {
  const initialCollapse = localStorage.getItem("sidebar-collapsed");
  if (initialCollapse !== null) {
    return JSON.parse(initialCollapse);
  }
  return false;
}

export default function MainLayout(props) {
  const { user } = React.useContext(AuthContext);
  const [collapsed, setCollapsed] = React.useState(getInitialSideNavState);

  React.useEffect(() => {
    localStorage.setItem("sidebar-collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  const toggleSideNav = () => {
    setCollapsed(collapsedParam => !collapsedParam);
  };

  if (isMobile) {
    return (
      <MobileLayout
        {...props}
        user={user}
        collapsed={collapsed}
        toggleSideNav={toggleSideNav}
      />
    );
  }

  return (
    <LayoutComponent
      {...props}
      user={user}
      collapsed={collapsed}
      toggleSideNav={toggleSideNav}
    />
  );
}

class LayoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortcutsVisible: false
    };
  }

  handleKeyboard(event) {
    if (event.ctrlKey && event.keyCode === 112) {
      this.toggleShortcuts();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyboard.bind(this), false);
  }

  toggleShortcuts = () => {
    this.setState(({ shortcutsVisible }) => ({
      shortcutsVisible: !shortcutsVisible
    }));
  };

  hideShortcuts = () => {
    this.setState({
      shortcutsVisible: false
    });
  };

  render() {
    const { user } = this.props;

    return (
      <LayoutStyle>
        <Layout>
          <Header style={{ padding: 0, height: "auto" }}>
            <MainHeader
              user={user}
              toggleSideNav={this.props.toggleSideNav}
              toggleShortcuts={this.toggleShortcuts}
              {...this.props}
            />
          </Header>

          <Layout>
            <Sider
              style={{ margin: "22px 0px", minHeight: "100vh" }}
              trigger={null}
              collapsible
              collapsed={this.props.collapsed}
              breakpoint="xl"
            >
              {user && (
                <SideMenu
                  user={user}
                  toggleShortcuts={this.toggleShortcuts}
                  hideShortcuts={this.hideShortcuts}
                  shortcutsVisible={this.state.shortcutsVisible}
                  {...this.props}
                />
              )}
            </Sider>
            <ContentStyle>
              {this.props.children}
              <Version
                style={{
                  position: "absolute",
                  bottom: 4,
                  right: 8
                }}
              />
            </ContentStyle>
          </Layout>
        </Layout>
      </LayoutStyle>
    );
  }
}
