import { saveAs } from "file-saver/FileSaver";
import { FILE_TYPES } from "./constants";

export function saveDataAs(data, fileName, fileType) {
  const fileExt = getFileExtension(fileType);
  const file = new File(
    [data],
    `${fileName}-${getFormattedTime()}.${fileExt}`,
    {
      type: fileType
    }
  );
  saveAs(file);
}

function getFormattedTime() {
  const today = new Date();
  const y = today.getFullYear();
  // JavaScript months are 0-based.
  const pad2 = getPad(2);
  const m = pad2(today.getMonth() + 1);
  const d = pad2(today.getDate());
  const h = pad2(today.getHours());
  const mi = pad2(today.getMinutes());
  const s = pad2(today.getSeconds());

  return `${y}${m}${d}${h}${mi}${s}`;
}

function getPad(size) {
  return num => {
    const s = `0${num}`;
    return s.substr(s.length - size);
  };
}

export function saveDataAsPDF(data, fileName) {
  const file = new File([data], `${fileName}.pdf`, {
    type: FILE_TYPES.PDF
  });
  saveAs(file);
}

export function saveDataAsCSV(data, fileName) {
  const file = new File([data], `${fileName}.csv`, {
    type: FILE_TYPES.CSV
  });
  saveAs(file);
}

export function getFileExtension(fileType) {
  if (fileType === FILE_TYPES.EXCEL) {
    return "xls";
  }
  if (fileType === FILE_TYPES.PDF) {
    return "pdf";
  }
  if (fileType === FILE_TYPES.ONLY_CSV) {
    return "csv";
  }
  return "txt";
}
