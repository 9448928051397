import React from "react";
import StyledSVGIcon from "./StyledSVGIcon";

const IconDoorEnter = () => (
  <StyledSVGIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M13 4V3a1 1 0 0 1 1.27-.96l6.98 2A1 1 0 0 1 22 5v14a1 1 0 0 1-.75.97l-6.98 2A1 1 0 0 1 13 21v-1h-3a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v1h2V6h-2v1a1 1 0 0 1-2 0V5a1 1 0 0 1 1-1h3z" />
    <path d="M7.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4L7.6 13H3a1 1 0 0 1 0-2h4.59z" />
  </StyledSVGIcon>
);

export default IconDoorEnter;
