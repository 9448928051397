import React from "react";
import "./KeyboardShortcuts.scss";
import { LaptopOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { getID } from "utils";

const KeyboardShortcutsWrapper = styled.div`
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  ul.shortcut-list {
    display: flex;
    flex-wrap: wrap;
  }
  ul.shortcut-list > li {
    flex: 0 0;
  }
`;

function parseShortcutsToArray(shortcuts) {
  if (shortcuts.constructor === Array) {
    return shortcuts;
  }
  return Object.values(shortcuts);
}

const KeyboardShortcuts = ({ shortcuts, children = null }) => {
  if (children !== null) {
    return children;
  }
  const shortcutsArray = parseShortcutsToArray(shortcuts);

  return (
    <KeyboardShortcutsWrapper className="tile is-ancestor navbar keyboard-shortcuts">
      <ul
        className="is-size-7-mobile tooltip is-tooltip-info is-tooltip-top tile is-parent"
        data-tooltip="Atajos de teclado"
      >
        <li className="is-size-4 tile is-child">
          <LaptopOutlined />
        </li>
      </ul>
      <ul className="shortcut-list">
        {shortcutsArray
          .filter(shortcut => shortcut.key)
          .map(shortcut => (
            <li key={getID("shortcuts")} className="navbar-item tile is-child">
              <span className="tag is-info key-name is-block">
                {shortcut.key}
              </span>
              <span className="tag is-light key-desc is-block">
                {shortcut.desc || shortcut.description}
              </span>
            </li>
          ))}
      </ul>
    </KeyboardShortcutsWrapper>
  );
};

export default KeyboardShortcuts;
