import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { MailOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";

import { requestPasswordRecovery } from "../../api/authService";
import { showError } from "../../utils";
import "./Form.scss";

const StyledContent = styled.div`
  text-align: center;
  padding-top: 20px;
`;

const TextInfo = styled.p`
  font-size: 15px;
  color: #a7a2a2;
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  margin: auto;
  width: 280px;
`;

const width = "250px";
class RecoveryForm extends React.Component {
  state = { loading: false };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, data) => {
      if (err) {
        return;
      }
      this.setState({ loading: true }, this.requestRecovery(data));
    });
  };

  requestRecovery = data => {
    requestPasswordRecovery(data)
      .then(() => {
        notification.success({
          message: "El link de recuperación fue enviado a su correo"
        });
      })
      .catch(showError)
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Form.Item style={{ marginBottom: "7px" }}>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Requerido" },
              { type: "email", message: "Email inválido" }
            ]
          })(
            <Input
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email"
              style={{ width }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width, marginBottom: "20px" }}
          >
            {loading ? "Enviando" : "Enviar Link"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const WrappedForm = Form.create()(RecoveryForm);

export default function PasswordRecovery() {
  return (
    <div className="login">
      <legend className="legend">Recuperación de Contraseña</legend>
      <StyledContent>
        <TextInfo>
          Ingrese su email y le enviaremos un link para recuperar su contraseña
        </TextInfo>
        <WrappedForm />
      </StyledContent>
    </div>
  );
}
