import React from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";

export default class FocusBox extends React.Component {
  state = { focus: false };

  focus = () => {
    this.setState({ focus: true });
  };

  blur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { faIcon, ...restProps } = this.props;
    return (
      <div onFocus={this.focus} onBlur={this.blur} {...restProps}>
        {this.props.children}
        {faIcon && (
          <span className={this.state.focus ? "focus" : ""}>
            <LegacyIcon type={faIcon} />
          </span>
        )}
      </div>
    );
  }
}
