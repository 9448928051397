import { message } from "../components/UI/Messages";

export const DEFAULT_ERROR_MSG =
  "Lo sentimos, no se pudo completar la operación";

export function handlePromiseError({
  error,
  messageComponent,
  /* eslint-disable-next-line */
  message = error.response.data && error.response.data.message
    ? error.response.data.message
    : DEFAULT_ERROR_MSG
}) {
  console.error({ error });
  messageComponent.error(message);
  return Promise.reject(error);
}

export function showError(error, messageProps) {
  let errorMessage = DEFAULT_ERROR_MSG;

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.data && error.data.message) {
    errorMessage = error.data.message;
  }

  console.error({ error });
  showErrorMessage(errorMessage, messageProps);
  return Promise.reject(error);
}

export function showErrorMessage(errorMessage, messageProps = {}) {
  const { duration = 1.5 } = messageProps;
  message.error(errorMessage, duration);
}

export function getErrorReport(error) {
  if (!error) {
    return {};
  }
  const { response } = error;
  if (!response) {
    return {};
  }
  if (response.status === 403) {
    return { hasError: true, page: "forbidden" };
  }
  if (response.status === 404) {
    return { hasError: true, page: "notfound" };
  }
  if (response.status === 500) {
    return { hasError: true, page: "error" };
  }
  return { hasError: false };
}

export function isNotFoundError(error) {
  return error.response && error.response.status === 404;
}

export function isValidationError(error) {
  return error.response && error.response.status === 400;
}

export function isError(error = null) {
  return error !== null && error.response && Number(error.response.status) > 0;
}
