import React from "react";
import styled from "styled-components";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { withRouter } from "react-router-dom";

import logo from "images/logo_transparent.png";

import "./Header.scss";

const HeaderWrapper = styled.nav`
  max-height: 3rem;

  a {
    line-height: 1.5;
    position: relative;
    display: block;
    img {
      height: 100%;
      width: auto;
    }
  }

  a.logo-link {
    padding: 0.5rem 1rem;
    margin-right: auto;
  }

  div.main-search > input {
    background: #f5f5f5;
  }

  .icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      padding: 0 1rem;
    }
  }

  .trigger:hover {
    color: #1890ff;
  }
`;

function MobileHeader(props) {
  const toggleSideNav = e => {
    e.preventDefault();
    props.toggleSideNav();
  };

  return (
    <div>
      <HeaderWrapper
        className="navbar lin-navbar has-shadow"
        aria-label="main navigation"
      >
        <div className="navbar-brand is-boxed">
          <div className="menu-trigger is-vertical-center">
            <LegacyIcon
              className="trigger"
              type={props.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={toggleSideNav}
            />
          </div>
          <a className="logo-link" href="/">
            <img src={logo} alt="logo" className="logo" />
          </a>
        </div>
      </HeaderWrapper>
    </div>
  );
}

export default withRouter(MobileHeader);
