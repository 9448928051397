import { FORMAT } from "./constants";
import { isNumeric, requiredParam } from "./functions";

function _isWholeNumber(number) {
  return number % 1 === 0;
}

function _formatNumber(number, options) {
  // FIXME este locale es incorrecto, se debe usar es-PY
  // Por algún motivo con es-PY no agrega separador a los numeros
  // menores con dos digitos en los miles.
  const formated = new Intl.NumberFormat("de-DE", {
    style: "decimal",
    minimumFractionDigits: options.minimumFractionDigits,
    maximumFractionDigits: options.maximumFractionDigits
  }).format(number);
  return formated;
}

export function formatNumberWith(decimals) {
  return value => {
    const decimalsParam = _isWholeNumber(value) ? 0 : decimals;
    return formatNumber(value, decimalsParam);
  };
}

export function formatNumber(value, decimalDigits = null) {
  if (value == null) {
    return null;
  }

  if (!isNumeric(value)) {
    return value;
  }

  const number = Number(value);

  // set decimal digits
  const stringNumber =
    decimalDigits === null ? number.toString() : number.toFixed(decimalDigits);

  // No usamos Intl.NumberFormat porque con el locale de es-PY
  // no agrega separador de miles correctamente.
  return stringNumber
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // use . as a separator
}

/**
 * This is american USD notation for currencies.
 * Uses decimals as thousands separator and point as decimal separator.
 *
 * @param {*} params
 */
export function currencyFormatter(params = {}) {
  const {
    value = requiredParam("value"),
    locale = FORMAT.LOCALE,
    maximumFractionDigits = FORMAT.MAXIMUN_FRACTION_DIGITS,
    currency = FORMAT.CURRENCY,
    currencyDisplay = "symbol"
  } = params;
  return formatPrice(value, {
    locale,
    maximumFractionDigits,
    currency,
    currencyDisplay
  });
}

export function numberFormatter(params = {}) {
  const {
    value,
    maximumFractionDigits = FORMAT.MAXIMUN_FRACTION_DIGITS,
    minimumFractionDigits = 3
  } = params;

  // FIXME deberia retornar null? ver donde afecta si se modifica
  if (value == null) {
    return "";
  }

  const formattedNumber = _formatNumber(value, {
    maximumFractionDigits,
    minimumFractionDigits: _isWholeNumber(value) ? 0 : minimumFractionDigits
  });

  return formattedNumber;
}

export function getNumberFormatter(params = {}) {
  const { fractionDigits = FORMAT.MAXIMUN_FRACTION_DIGITS } = params;
  return function internalNumberFormatter({ value }) {
    if (!value) {
      return "";
    }
    const formattedNumber = _formatNumber(value, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits
    });

    return formattedNumber;
  };
}

export function parseNumber(formattedNumber) {
  const numberWithoutSeparators = replaceSeparators(formattedNumber);
  if (numberWithoutSeparators === null) {
    return null;
  }
  return parseFloat(numberWithoutSeparators);
}

export function gridNumberParser({ newValue }) {
  return parseNumber(newValue);
}

export function formatPrice(number, options) {
  const formated = new Intl.NumberFormat(options.locale, {
    style: "currency",
    currency: options.currency,
    currencyDisplay: options.currencyDisplay,
    minimumFractionDigits: 0,
    maximumFractionDigits: options.maximumFractionDigits
  }).format(number);
  return formated;
}

export function getNumberFromCurrencyString(value) {
  if (value == null) {
    return "";
  }
  if (typeof value === "number") {
    return value.toString();
  }
  const newFormat = value.replace(/(USD |\.| %)/g, "");
  return newFormat.replace(",", ".");
}

export function replaceSeparators(value) {
  // null or undefined
  if (value == null) {
    return null;
  }
  if (typeof value === "number") {
    return value;
  }
  const newFormat = value.replace(/\./g, "");
  return newFormat.replace(",", ".");
}
export function formatToUSD(amount, decimal = 2) {
  const numberDecimal = typeof decimal !== "object" ? decimal : undefined ?? 2;
  try {
    const options = {
      style: "decimal",
      minimumFractionDigits: numberDecimal,
      maximumFractionDigits: numberDecimal
    };
    // TODO Es incorrecto usar el formato es-PY
    // pero con esta opción logramos tener separador de decimales
    // con coma ",".
    // Ver una mejor manera de que esta implementación sea más
    // reveladora de la intención.
    const formatter = new Intl.NumberFormat("es-PY", options);
    return formatter.format(amount);
  } catch (error) {
    return 0.0;
  }
}
export function formatToUSDFormat(amount) {
  try {
    const options = {
      currency: "USD", // Utilizamos USD como ejemplo, puedes cambiarlo a tu moneda deseada
      maximumFractionDigits: 2,
      useGrouping: true
    };
    const formatter = new Intl.NumberFormat("en-US", options);
    return formatter.format(amount);
  } catch (error) {
    return 0.0;
  }
}
export function formatToPYG(amount) {
  const options = {
    style: "decimal"
  };
  const formatter = new Intl.NumberFormat("es-PY", options);
  const formattedValue = formatter.format(amount);
  return formattedValue;
}

export function percentFormatter({ value }) {
  return formatToPercent(value);
}

export function formatToPercent(value) {
  if (value == null) {
    return "";
  }
  const formattedPercentage = numberFormatter({
    value,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
  return `${formattedPercentage} %`;
}

export function roundNumber(number, decimals) {
  const newNumber = Number(Math.round(number * 100) / 100).toFixed(
    parseInt(decimals, 0)
  );
  return parseFloat(newNumber);
}

export function roundNumberHalfUp(number) {
  const newNumber = Math.ceil(number);
  return parseFloat(newNumber);
}

export function amountFormatter({ data, colDef }, fieldName = "amount") {
  if (!data) {
    return "";
  }
  const amount = formatNumber(data[colDef.field || fieldName]);
  if (amount == null) {
    return "";
  }
  if (data.currency == null) {
    return amount;
  }
  return `${data.currency.value} ${amount}`;
}

export function isInt(value) {
  const er = /^-?[0-9]+$/; // Allow only numbers
  return er.test(value);
}

export function isValidTypeDocument(value) {
  const er = /^[A-Za-z0-9-]*$/; // Allow number, letters and dash
  return er.test(value);
}

export function formatAmount(amountValue, record) {
  const amount = formatNumber(amountValue);
  if (!record.currency) {
    return amount;
  }
  return `${record.currency.value} ${amount}`;
}
