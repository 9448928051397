import React, { Component } from "react";
import PasswordRecovery from "../../components/Login/PasswordRecovery";

// eslint-disable-next-line react/prefer-stateless-function
export default class ScreensLoginPasswordRecovery extends Component {
  render() {
    return (
      <div className="login-container">
        <PasswordRecovery {...this.props} />
      </div>
    );
  }
}
