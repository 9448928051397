import React from "react";
import PropTypes from "prop-types";
import Spin from "antd/lib/spin";

const styles = {
  content: {
    position: "absolute",
    left: "0",
    right: "0",
    marginTop: "20px",
    textAlign: "center"
  }
};

export default function Loading({ speed = 300, text = "Cargando" }) {
  const [content, setContent] = React.useState(text);
  const intervalRef = React.useRef();

  React.useEffect(() => {
    intervalRef.current = window.setInterval(() => {
      setContent(contentParam =>
        contentParam === `${text}...` ? text : `${contentParam}.`
      );
    }, speed);

    return () => window.clearInterval(intervalRef.current);
  }, [speed, text]);

  return (
    <div style={styles.content}>
      <Spin spinning tip={content} />
    </div>
  );
}

Loading.propTypes = {
  text: PropTypes.string,
  speed: PropTypes.number
};

Loading.defaultProps = {
  text: "Cargando",
  speed: 300
};
