import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Tooltip from "antd/lib/tooltip";

const renderNavLink = ({ className, to, activeClassName, children }) => {
  return (
    <NavLink className={className} to={to} activeClassName={activeClassName}>
      {children}
    </NavLink>
  );
};

export default class MenuNavLink extends Component {
  static propTypes = {
    // eslint-disable-next-line react/require-default-props
    tooltip: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    to: PropTypes.string.isRequired
  };

  render() {
    const { tooltip } = this.props;
    if (tooltip) {
      return (
        <Tooltip placement="rightTop" title={tooltip}>
          {renderNavLink(this.props)}
        </Tooltip>
      );
    }
    return renderNavLink(this.props);
  }
}
