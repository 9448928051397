import React from "react";
import ReactDOM from "react-dom";
import "./styles/app.global.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "moment/locale/es";
import locale from "antd/es/locale/es_ES";
import { ConfigProvider } from "antd";

import { AuthProvider } from "components/Auth/useAuth";
import Loading from "components/UI/Loading";
import ScreensLogin from "screens/Login";
import ScreensRecovery from "screens/Login/PasswordRecovery";
import ScreensReset from "screens/Login/PasswordReset";
import ScreensPublicArticle from "screens/Public/PublicArticle";
import AuthenticatedRoute from "components/Auth/AuthenticatedRoute";
import ScreensRoot from "./screens/Root/Root";
import registerServiceWorker from "./registerServiceWorker";

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Router>
        <AuthProvider>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/login" component={ScreensLogin} />
              <Route path="/password/recover" component={ScreensRecovery} />
              <Route path="/password/reset" component={ScreensReset} />
              <AuthenticatedRoute
                path="/packing/consolidate"
                component={React.lazy(() =>
                  import("screens/PackingConsolidateScreen")
                )}
              />
              <AuthenticatedRoute
                path="/shipping-consolidated/board/:shippingId"
                component={React.lazy(() =>
                  import(
                    "screens/LoadingDockBoardScreen/LoadingDockBoardScreen"
                  )
                )}
              />
              <Route
                path="/public/article/:articleCode"
                exact
                component={ScreensPublicArticle}
              />

              <Route component={ScreensRoot} />
            </Switch>
          </React.Suspense>
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
