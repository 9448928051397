import React from "react";
import styled from "styled-components";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import Icon from "@ant-design/icons";

const MenuItemTitleStyle = styled.div`
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px #e3e8ee;
  padding-bottom: 16px;

  svg {
    color: #f6993f;
  }
  span {
    vertical-align: middle;
  }
`;

const iconStyle = {
  fontSize: "1.125rem"
};

const MenuItemTitle = ({
  icon = () => null,
  children,
  iconComp,
  ...restProps
}) => (
  <MenuItemTitleStyle {...restProps}>
    {typeof icon === "string" && <LegacyIcon type={icon} style={iconStyle} />}
    {typeof icon === "function" && <Icon component={icon} style={iconStyle} />}
    {typeof icon === "object" &&
      React.isValidElement(icon) &&
      React.cloneElement(icon, { style: iconStyle })}

    <span className="menu-text">{children}</span>
  </MenuItemTitleStyle>
);

export default MenuItemTitle;
