import React from "react";
import "./Form.scss";

import { Button } from "antd";
import message from "antd/lib/message";
import "antd/lib/button/style/css";
import "antd/lib/message/style/css";
import { AuthContext } from "components/Auth/useAuth";
import {
  getQueryParamValue,
  REDIRECT_FROM_QUERY_PARAM_NAME,
  REDIRECT_FROM_VALUES
} from "utils";
import FocusBox from "./FocusBox";
import Version from "../UI/Version";

function LoginForm(props) {
  const { login, logout } = React.useContext(AuthContext);
  return <Form {...props} login={login} logout={logout} />;
}

class Form extends React.Component {
  state = {
    loading: false,
    password: ""
  };

  _isMounted = false;

  handleChange(event) {
    this.setState({ password: event.target.value });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  login = e => {
    e.preventDefault();
    this.setState({ loading: true });
    const cred = {
      username: this.username.value,
      password: this.password.value
    };
    this.props
      .login(cred)
      .then(() => {
        this.afterLogin();
      })
      .catch(error => {
        console.error({ error });
        if (error.response) {
          const { data } = error.response;
          if (!data.success) {
            message.error(
              "Las credenciales ingresadas no corresponden a ninguna cuenta. Favor intente de nuevo.",
              5
            );
          }
        } else {
          message.error(error.message);
        }
      })
      .finally(() => {
        if (this._isMounted) {
          this.setState({ loading: false });
        }
      });
  };

  afterLogin() {
    const { location } = this.props;
    if (!location.state) {
      this.props.history.push({ pathname: "/" });
      return;
    }

    const { from = null } = location.state;
    const value =
      from === null
        ? null
        : getQueryParamValue(from, REDIRECT_FROM_QUERY_PARAM_NAME);

    if (REDIRECT_FROM_VALUES.includes(value)) {
      this.props.history.push(from);
    } else {
      this.props.history.push({ pathname: "/" });
    }
  }

  render() {
    const { loading, password } = this.state;
    return (
      <div>
        <form className="login" onSubmit={e => this.login(e)}>
          <fieldset>
            <legend className="legend">Iniciar Sesión</legend>
            <FocusBox className="login-input" faIcon="user">
              <input
                className="input"
                type="text"
                placeholder="Usuario o correo"
                required
                ref={input => (this.username = input)}
              />
            </FocusBox>
            <FocusBox className="login-input" faIcon="lock">
              <input
                className="input"
                type="password"
                placeholder="Contraseña"
                required
                ref={input => (this.password = input)}
                value={password}
                onChange={e => this.handleChange(e)}
              />
              <a
                className="ant-btn-link"
                style={{ fontSize: "12.5px" }}
                href="/password/recover"
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿Olvidó su contraseña?
              </a>
            </FocusBox>
            <Button
              type="primary"
              className="login-btn"
              htmlType="submit"
              loading={loading}
            >
              Iniciar Sesión
            </Button>
          </fieldset>

          <div className="feedback">
            login successful <br />
            redirecting...
          </div>
        </form>
        <Version />
      </div>
    );
  }
}

export default LoginForm;
