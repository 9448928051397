import React from "react";
import ArticlePublicDetail from "../../components/Article/ArticleDetail";
import "./PublicArticle.scss";

const ScreensPublicArticle = ({
  match: {
    params: { articleCode }
  }
}) => {
  return <ArticlePublicDetail param={articleCode} />;
};

export default ScreensPublicArticle;
