import axios from "axios";
import { PUBLIC_API_URL } from "../utils/constants";

export function getArticleInfo(code) {
  return axios
    .get(`${PUBLIC_API_URL}/public/article/${code}`)
    .then(json => {
      const { data } = json;
      return { response: data.dto };
    })
    .catch(error => {
      return Promise.reject(error.response);
    });
}

export default { getArticleInfo };
