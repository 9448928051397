import React from "react";
import StyledSVGIcon from "./StyledSVGIcon";

const IconMoney = () => (
  <StyledSVGIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18 14.74a4 4 0 0 0-8 .26H3a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-1 1.74zM10 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
    <path d="M7 9h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm7 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
  </StyledSVGIcon>
);

export default IconMoney;
