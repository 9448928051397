import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Avatar from "antd/lib/avatar";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import { LogoutOutlined, SafetyOutlined } from "@ant-design/icons";
import "antd/lib/avatar/style/css";
import "antd/lib/menu/style/css";
import "antd/lib/dropdown/style/css";
import "antd/lib/icon/style/css";

const StyledDropdown = styled(Dropdown)`
  ul > li.ant-dropdown-menu-item.username {
    padding: 0 0.75rem;
  }

  .ant-avatar {
    background-color: #7265e6;
    vertical-align: middle;
  }
`;

const renderMenu = (user, logout, onChangePassword) => (
  <Menu>
    <Menu.Item className="username">
      <h1>{user && user.fullName}</h1>
      {user && user.username}
    </Menu.Item>
    <Menu.Item onClick={onChangePassword}>
      <SafetyOutlined />
      Cambiar contraseña
    </Menu.Item>
    <Menu.Item>
      <a href="/#" onClick={logout}>
        <LogoutOutlined /> Salir
      </a>
    </Menu.Item>
  </Menu>
);

function Profile({ user, logout, onChangePassword }) {
  return (
    <StyledDropdown overlay={renderMenu(user, logout, onChangePassword)}>
      <a
        className="ant-dropdown-link"
        href="/#"
        onClick={e => e.preventDefault()}
      >
        <Avatar>
          <span>{user && user.profileDisplayInitial}</span>
        </Avatar>
      </a>
    </StyledDropdown>
  );
}

Profile.propTypes = {
  logout: PropTypes.func.isRequired
};

export default Profile;
