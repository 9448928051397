/* eslint-disable */
import React, { Component } from "react";
import KeyboardShortcuts from "../KeyboardShortcuts";
import Drawer from "antd/lib/drawer";
import "antd/lib/drawer/style/css";
import PropTypes from "prop-types";

export default class ShortcutsDrawer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultPropTypes = {
    visible: false,
    onClose: () => null
  };

  basicShortcuts = [
    {
      key: "CTRL+F1",
      description: "Mostrar/Ocultar información de atajos de teclado"
    },
    { key: "F2", description: "Editar celda" },
    { key: "F4", description: "Foco en grilla" },
    {
      key: "ENTER",
      description: (
        <span>
          - Ver detalle de registro seleccionado
          <br />- Editar celda
        </span>
      )
    },
    { key: "ESPACIO", description: "Seleccionar/Deseleccionar registro" },
    { key: "SUPR", description: "Eliminar seleccionados" },
    {
      key: "F8",
      description: "Seleccionar/Deseleccionar todos en página actual"
    },
    {
      key: "F9",
      description: "Seleccionar/Deseleccionar todos los resultados"
    },
    {
      key: "CTRL+F9",
      description: "Limpiar Filtros"
    },
    { key: "ESC", description: "Cancelar/Cerrar diálogos" }
  ];

  articleShortcuts = [
    { key: "INSERT", description: "Nuevo artículo" },
    { key: "CTRL+SHIFT+F", description: "Ir a filtro de columna" },
    { key: "CTRL+ENTER", description: "Editar artículo" },
    { key: "CTRL+F7", description: "Editar varios artículos" }
  ];

  ordersReceptionShortcuts = [
    { key: "F2", description: "Filtrar por Nro de pedido" },
    { key: "F4", description: "Copiar cantidad pedida a cantidad recibida" },
    { key: "INSERT", description: "Agregar Nro de Factura" }
  ];

  catalogDetailShortCuts = [
    { key: "INSERT", description: "Insertar nuevo artículo" },
    { key: "CTRL+ENTER", description: "Modificar varios valores" }
  ];

  getShortcutsTitleNode() {
    return (
      <div className="content has-text-weight-bold">
        <h2>Atajos de teclado</h2>
      </div>
    );
  }

  getKeyComponent(key) {
    if (key.includes("+")) {
      return key
        .split("+")
        .map((keyPart, index) => (
          <span className="tag" key={index}>
            {keyPart}
          </span>
        ))
        .reduce((prev, curr, index) => [
          prev,
          <span key={`${key}${index}`}>&nbsp;</span>,
          curr
        ]);
    }
    return <span className="tag">{key}</span>;
  }

  render() {
    const { visible, onClose } = this.props;
    return (
      <Drawer
        title={this.getShortcutsTitleNode()}
        visible={visible}
        closable
        onClose={e => console.log("on close") || onClose(e)}
        width="28em"
      >
        <div className="content">
          <KeyboardShortcuts>
            <h3 className="has-text-info">Básicos</h3>
            <table>
              <tbody>
                {this.basicShortcuts.map(({ key, description }) => (
                  <tr key={key}>
                    <td>{description}</td>
                    <td style={{ width: "120px" }}>
                      {this.getKeyComponent(key)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h3 className="has-text-info">Administración de Artículos</h3>
            <table>
              <tbody>
                {this.articleShortcuts.map(({ key, description }) => (
                  <tr key={key}>
                    <td>{description}</td>
                    <td>{this.getKeyComponent(key)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h3 className="has-text-info">Recepción de pedidos</h3>
            <table>
              <tbody>
                {this.ordersReceptionShortcuts.map(({ key, description }) => (
                  <tr key={`OR${key}`}>
                    <td>{description}</td>
                    <td>{this.getKeyComponent(key)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h3 className="has-text-info">Catálogo de precios</h3>
            <table>
              <tbody>
                {this.catalogDetailShortCuts.map(({ key, description }) => (
                  <tr key={`OR${key}`}>
                    <td>{description}</td>
                    <td>{this.getKeyComponent(key)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </KeyboardShortcuts>
        </div>
      </Drawer>
    );
  }
}
