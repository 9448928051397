import React from "react";
import { Route, Redirect } from "react-router-dom";

import { withErrorCheck } from "components/UI/ErrorHandler";
import { AuthContext } from "components/Auth/useAuth";

const makeRenderWithAuth = (auth, Component) => {
  return matchProps => {
    const isAuthenticated = auth.isAuthenticated();
    return isAuthenticated ? (
      <Component {...matchProps} user={auth.user} />
    ) : (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: matchProps.location }
        }}
      />
    );
  };
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const auth = React.useContext(AuthContext);
  return <Route {...rest} render={makeRenderWithAuth(auth, Component)} />;
};

export default withErrorCheck(AuthenticatedRoute);
