import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { LockOutlined } from "@ant-design/icons";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";

import authService, { updatePasswordByRecovery } from "../../api/authService";
import { showError } from "../../utils";
import auth from "../Auth";
import "./Form.scss";

const StyledContent = styled.div`
  text-align: center;
  padding-top: 20px;
`;

const InfoText = styled.p`
  font-size: 15px;
  color: #a7a2a2;
  font-weight: bold;
  padding: 0px 10px 20px 10px;
  width: 300px;
  margin: auto;
`;

const width = "250px";

class NewPasswordForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onChangePassword(values);
    });
  };

  validateConfirmPassword = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    if (value && value !== getFieldValue("newPassword")) {
      callback("La contraseña no coincide");
    }
    callback();
  };

  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} autoComplete="off">
        <Form.Item>
          {getFieldDecorator("newPassword", {
            rules: [{ required: true, message: "Campo Requerido" }]
          })(
            <Input
              name="new-password"
              placeholder="Nueva Contraseña"
              type="password"
              autoComplete="new-password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              style={{ width }}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("newPasswordRepetition", {
            rules: [
              { required: true, message: "Campo Requerido" },
              { validator: this.validateConfirmPassword }
            ]
          })(
            <Input
              name="confirm-password"
              placeholder="Confirmar Nueva Contraseña"
              type="password"
              autoComplete="new-password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              style={{ width }}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width, marginBottom: "20px" }}
          >
            {loading ? "Modificando" : "Modificar Contraseña"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const WrappedForm = Form.create()(NewPasswordForm);

export default class PasswordReset extends React.Component {
  state = { loading: false };

  onChangePassword = values => {
    this.setState({ loading: true }, this.updatePassword(values));
  };

  updatePassword = newValues => {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    const userId = query.get("id");
    updatePasswordByRecovery(token, { ...newValues, userId })
      .then(({ dto }) => {
        const { username } = dto;
        this.login(username, newValues.newPassword);
      })
      .catch(error => {
        showError(error);
        this.setState({ loading: false });
      });
  };

  login = (username, password) => {
    authService
      .authenticate({ username, password })
      .then(result => {
        const { secret: accessToken } = result.data;
        auth.setTokens({ accessToken });
        this.props.history.push(`/`);
        window.location.reload();
        notification.success({
          message: "La contraseña fue actualizada con éxito"
        });
      })
      .catch(showError)
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { loading } = this.state;
    return (
      <div className="login">
        <legend className="legend" style={{ paddingTop: "20px" }}>
          Nueva Contraseña
        </legend>
        <StyledContent>
          <InfoText>
            Ingrese una nueva contraseña y luego reingrese la misma
          </InfoText>
          <WrappedForm
            onChangePassword={this.onChangePassword}
            loading={loading}
          />
        </StyledContent>
      </div>
    );
  }
}
