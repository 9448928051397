import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import Menu from "antd/lib/menu";
import Layout from "antd/lib/layout";
import "antd/lib/layout/style/css";

import Version from "components/UI/Version";
import { AuthContext } from "components/Auth/useAuth";
import MenuItemTitle from "components/UI/Items/MenuItemTitle";

import MobileHeader from "./MobileHeader";
import SideMenu from "./SideMenu";

const { Header, Sider, Content } = Layout;

const MobileContentStyle = styled(Content)`
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  border-top: var(--page-section-border);
  box-shadow: var(--page-section-shadow);
  margin-top: 0px;
  margin-bottom: 10px;
  margin-right: 0px;
  padding: 1.5rem;
  background: #fff;
`;

const MobileLayoutStyle = styled.div`
  background: var(--background-color);
  .ant-layout-sider-children {
    background: var(--background-color);
  }
`;

function MobileLayout(props) {
  const { user } = props;
  return (
    <MobileLayoutStyle>
      <Layout>
        <Header style={{ padding: 0, height: "auto" }}>
          <MobileHeader {...props} />
        </Header>

        <Layout>
          <Sider
            style={{ minHeight: "100vh" }}
            breakpoint="xl"
            collapsed={props.collapsed}
            collapsedWidth="1"
          >
            {user && <MobileMenu {...props} />}
          </Sider>
          <MobileContentStyle>
            {props.children}
            <Version
              style={{
                position: "absolute",
                bottom: 4,
                right: 8
              }}
            />
          </MobileContentStyle>
        </Layout>
      </Layout>
    </MobileLayoutStyle>
  );
}

function MobileMenu(props) {
  const { logout } = React.useContext(AuthContext);
  const StyledSideMenu = styled.div`
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    background: #f0f2f5;
    .ant-menu,
    .ant-menu-submenu > .ant-menu {
      background-color: inherit;
    }

    .ant-menu-inline {
      .ant-menu-item > a > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-menu-iline-collapsed {
      .ant-menu-item > a > div {
        overflow: unset;
      }
    }
  `;

  const onLogout = async () => {
    try {
      await logout();
    } finally {
      props.history.push({ pathname: "/login" });
    }
  };

  return (
    <>
      <SideMenu user={props.user} />
      <StyledSideMenu>
        <Menu mode="inline">
          <Menu.Item onClick={onLogout}>
            <MenuItemTitle icon="logout">Salir</MenuItemTitle>
          </Menu.Item>
        </Menu>
      </StyledSideMenu>
    </>
  );
}

export default withRouter(MobileLayout);
