import { useState, useEffect } from "react";

const errorDefaults = {
  customErrorMessage: null,
  notAuthenticated: false,
  forbidden: false,
  serverError: false,
  timeoutOrConnection: false
};

function getResponseError(response) {
  // Errores de acceso/autorización
  if (response.status === 401) {
    // “you aren’t authenticated–either not authenticated at all
    // or authenticated incorrectly–but please reauthenticate and try again.”
    return {
      ...errorDefaults,
      notAuthenticated: true
    };
  }
  if (response.status === 403) {
    // “I’m sorry. I know who you are–I believe who you say you are–
    // but you just don’t have permission to access this resource.
    // Maybe if you ask the system administrator nicely, you’ll get permission.
    // But please don’t bother me again until your predicament changes.”
    return { ...errorDefaults, forbidden: true };
  }

  // Manejadores globales de errores con mensajes genéricos
  // Todo el resto de los 4XX deben ser manejados de forma individual por cada
  // componente
  if (response.status === 404) {
    // Do nothing, maybe the componente will want to handle this
  }

  if (response.status === 500) {
    return { ...errorDefaults, serverError: true };
  }

  return null;
}

function getRequestError(error) {
  if (error.code === "ECONNABORTED") {
    return {
      ...errorDefaults,
      timeoutOrConnection: true,
      customErrorMessage:
        "La petición ha tomado más tiempo del esperado. Vuelva a intentar en un momento."
    };
  }
  return {
    ...errorDefaults,
    timeoutOrConnection: true,
    customErrorMessage:
      "Error de conexión con el servidor, verifique su conexión a internet."
  };
}

function getError(error) {
  const { response } = error;

  if (response) {
    return getResponseError(response);
  }

  if (error.request) {
    // Errores de timeout y server abajo
    return getRequestError(error);
  }

  return null;
}

export default httpClient => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const reqInterceptor = httpClient.interceptors.request.use(req => {
      setError(null);
      return req;
    });

    const resInterceptor = httpClient.interceptors.response.use(
      res => res,
      err => {
        const parsedError = getError(err);
        setError(parsedError);
        return Promise.reject(err);
      }
    );
    return () => {
      httpClient.interceptors.request.eject(reqInterceptor);
      httpClient.interceptors.response.eject(resInterceptor);
    };
  }, [httpClient.interceptors.request, httpClient.interceptors.response]);

  const errorConfirmedHandler = () => {
    setError(null);
  };

  return [error, errorConfirmedHandler];
};
