import axios from "axios";
import { getRoleName } from "components/Auth/auth.utils";
import { API_URL, serializeToUri } from "utils";
import { handleApiResponse } from "./helpers";

export function updateUserPassword(passwordDTO) {
  return axios
    .put(`${API_URL}/secure/users/attributes/password`, passwordDTO)
    .then(handleApiResponse);
}

export function getRoles(params = {}) {
  const { name = "", description, paginationAndFilter = {} } = params;
  const descriptionURL = description ? `&description=${description}` : "";
  return axios
    .get(
      `${API_URL}/secure/roles?name=${name + descriptionURL}`,
      paginationAndFilter
    )
    .then(handleApiResponse);
}

export function getUsers(params = {}) {
  const { username = "", email = "", name, lastname, pagination = {} } = params;
  const nameParam = name ? `&name=${name}` : "";
  const lastnameParam = lastname ? `&lastname=${lastname}` : "";

  return axios
    .get(
      `${API_URL}/secure/users?username=${username}&email=${email}${nameParam}${lastnameParam}`,
      pagination
    )
    .then(handleApiResponse);
}

export function getUsersByRoles(roles) {
  const urlParams = serializeToUri({ roles: roles.map(getRoleName) });
  return axios
    .get(`${API_URL}/secure/users/roles?${urlParams}`)
    .then(handleApiResponse);
}

export function createUser(newUser) {
  return axios.post(`${API_URL}/secure/users`, newUser).then(handleApiResponse);
}

export function updateUser(user) {
  return axios
    .put(`${API_URL}/secure/users/${user.id}`, user)
    .then(handleApiResponse);
}

export function getUsersWithSector() {
  return axios.get(`${API_URL}/secure/users/locations`).then(handleApiResponse);
}

export function getUserInfo() {
  return axios.get(`${API_URL}/secure/user-info`).then(handleApiResponse);
}

export function getUserSessionAudit(gridParams, filter = {}) {
  const params = {
    filterModel: {
      ...filter,
      ...gridParams.filterModel
    }
  };
  return axios
    .post(`${API_URL}/secure/session-audit/filter`, params)
    .then(handleApiResponse);
}

export function searchUsers() {
  return axios.get(`${API_URL}/secure/users`).then(handleApiResponse);
}

export function getUsersByCharges(charges) {
  return axios
    .get(`${API_URL}/secure/users/by-charges`, {
      params: { charges: `${charges}` }
    })
    .then(handleApiResponse);
}
