import axios from "axios";
import auth from "components/Auth";

const setupTokenInterceptor = () => {
  const onRequestSuccess = config => {
    const token = auth.getAccessToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers["X-JOKO-AUTH"] = token;
    }
    // eslint-disable-next-line no-param-reassign
    config.timeout = 120000;
    return config;
  };

  axios.interceptors.request.use(onRequestSuccess);
};

export default { setupTokenInterceptor };
