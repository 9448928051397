import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Menu from "antd/lib/menu";
import {
  ProfileOutlined,
  TransactionOutlined,
  WalletOutlined
} from "@ant-design/icons";
import { IconDoorEnter, IconMoney } from "components/UI/CustomIcon";
import MenuItemTitle from "components/UI/Items/MenuItemTitle";
import ShortcutsDrawer from "components/Shortcuts/Drawer";
import { AuthContext, ROLES } from "components/Auth";
import { getID } from "utils";
import MenuNavLink from "./MenuNavLink";

const { SubMenu } = Menu;

const StyledSideMenu = styled.div`
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  background: #f0f2f5;

  .ant-menu,
  .ant-menu-submenu > .ant-menu {
    background-color: inherit;
  }

  .ant-menu-inline {
    .ant-menu-item > a > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ant-menu-iline-collapsed {
    .ant-menu-item > a > div {
      overflow: unset;
    }
  }
`;

const adminMainItems = [
  <Menu.Item key={getID()}>
    <MenuNavLink to="/suggestions">
      <MenuItemTitle icon="carry-out">Sugerencias</MenuItemTitle>
    </MenuNavLink>
  </Menu.Item>,
  <Menu.Item key={getID()}>
    <MenuNavLink to="/proformas">
      <MenuItemTitle icon="shopping-cart">Proformas</MenuItemTitle>
    </MenuNavLink>
  </Menu.Item>,
  <Menu.Item key={getID()}>
    <MenuNavLink to="/orders/list">
      <MenuItemTitle icon="ordered-list">Pedidos</MenuItemTitle>
    </MenuNavLink>
  </Menu.Item>,
  <Menu.Item key={getID()}>
    <MenuNavLink to="/catalog">
      <MenuItemTitle icon="book">Catálogos</MenuItemTitle>
    </MenuNavLink>
  </Menu.Item>,
  <Menu.Item key={getID()}>
    <MenuNavLink to="/supplier">
      <MenuItemTitle icon="shop">Proveedores</MenuItemTitle>
    </MenuNavLink>
  </Menu.Item>
];

class SideNav extends Component {
  static propTypes = {
    toggleShortcuts: PropTypes.func.isRequired,
    hideShortcuts: PropTypes.func.isRequired
  };

  toggleShortcuts = e => {
    e.preventDefault();
    this.props.toggleShortcuts();
  };

  hideShortcuts = e => {
    e.preventDefault();
    this.props.hideShortcuts();
  };

  render() {
    const { user, shortcutsVisible } = this.props;
    return (
      <StyledSideMenu>
        <Menu mode="inline">
          <Menu.Item key={getID()}>
            <MenuNavLink to="/home">
              <MenuItemTitle icon="home">Inicio</MenuItemTitle>
            </MenuNavLink>
          </Menu.Item>

          {user.hasAnyRole("ADMIN") && adminMainItems.map(item => item)}

          <ProtectedItem
            key={getID()}
            roles={[
              ROLES.ENCARGADO_COMERCIAL,
              ROLES.GERENTE_GENERAL,
              ROLES.ENCARGADO_CTA_CORRIENTE
            ]}
          >
            <MenuNavLink to="/uvm/salesman">
              <MenuItemTitle icon={IconMoney}>Ventas</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem key={getID()} roles={[ROLES.GERENTE_GENERAL]}>
            <MenuNavLink to="/gratification">
              <MenuItemTitle icon="fund-projection-screen">
                Gratificaciones
              </MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[ROLES.ENCARGADO_COBRANZAS, ROLES.ENCARGADO_CTA_CORRIENTE]}
          >
            <MenuNavLink to="/uvm/payment">
              <MenuItemTitle icon="bank">Cobranzas</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem key={getID()} roles={[ROLES.ENCARGADO_CTA_CORRIENTE]}>
            <MenuNavLink to="/uvm/proformas/credit-unit">
              <MenuItemTitle icon="shopping-cart">Proformas - UC</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem key={getID()} roles={[ROLES.OPERADOR_VENTAS]}>
            <MenuNavLink to="/uvm/proformas/sales">
              <MenuItemTitle icon="shopping-cart">
                Proformas - Ventas
              </MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[
              ROLES.ADMIN,
              ROLES.ASISTENTE_ADMINISTRATIVO,
              ROLES.OPERADOR_ADMINISTRATIVO
            ]}
          >
            <MenuNavLink to="/voucher">
              <MenuItemTitle icon="audit">Comprobantes</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[
              ROLES.ADMIN,
              ROLES.ASISTENTE_ADMINISTRATIVO,
              ROLES.ENCARGADO_DEVOLUCION
            ]}
          >
            <MenuNavLink to="/return">
              <MenuItemTitle icon={IconDoorEnter}>Devoluciones</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[
              ROLES.ENCARGADO_DEPOSITO,
              ROLES.SECTOR_EMPAQUE_DEPOSITO,
              ROLES.SECTOR_PREPARACION_DEPOSITO,
              ROLES.ENCARGADO_CONSOLIDACION,
              ROLES.ENCARGADO_FACTURACION,
              ROLES.OPERADOR_EMBARQUE
            ]}
          >
            <MenuNavLink to="/warehouse/order">
              <MenuItemTitle icon="container">Pedidos Depósito</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[
              ROLES.SECTOR_EMPAQUE_DEPOSITO,
              ROLES.SECTOR_PREPARACION_DEPOSITO
            ]}
          >
            <MenuNavLink to="/warehouse/reentry">
              <MenuItemTitle icon="rollback">Reingresos</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>
          <ProtectedItem key={getID()} roles={[ROLES.DEPOSITO]}>
            <MenuNavLink to="/warehouse">
              <MenuItemTitle icon="audit">Recepción</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            roles={[ROLES.ENCARGADO_FACTURACION, ROLES.OPERADOR_EMBARQUE]}
            key={getID()}
          >
            <MenuNavLink to="/uvm/logistics#1">
              <MenuItemTitle icon="environment">Logística</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[ROLES.OPERADOR_ADMINISTRATIVO, ROLES.ENCARGADO_REPOSICION]}
          >
            <MenuNavLink to="/movement">
              <MenuItemTitle icon="retweet">Movimientos</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem
            key={getID()}
            roles={[ROLES.ADMIN, ROLES.VENTAS_ECOMMERCE]}
          >
            <MenuNavLink to="/ecommerce">
              <MenuItemTitle icon="global">eCommerce</MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>

          <ProtectedItem key={getID()} roles={[ROLES.CONTABILIDAD]}>
            <MenuNavLink to="/accountancy/tabs">
              <MenuItemTitle icon="file-protect">
                Docs. Tributarios
              </MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>
          <ProtectedItem key={getID()} roles={[ROLES.ADMIN]}>
            <MenuNavLink to="/mifactura">
              <MenuItemTitle icon="file-protect">
                Facturación Electrónica
              </MenuItemTitle>
            </MenuNavLink>
          </ProtectedItem>
          <ProtectedSubMenu
            roles={[ROLES.ADMIN]}
            key={getID()}
            title={
              <span>
                <WalletOutlined />
                <span>Costeo</span>
              </span>
            }
          >
            <Menu.Item>
              <MenuNavLink to="/orders/reception">
                <MenuItemTitle icon="money-collect">Facturas</MenuItemTitle>
              </MenuNavLink>
            </Menu.Item>
            <Menu.Item>
              <MenuNavLink to="/entrance">
                <MenuItemTitle icon="inbox">Entradas</MenuItemTitle>
              </MenuNavLink>
            </Menu.Item>
          </ProtectedSubMenu>

          <ModuleAdminMenu key={getID()}>
            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.ADMIN,
                ROLES.ENCARGADO_MARCAS,
                ROLES.OPERADOR_ARTICULOS,
                ROLES.OPERADOR_ADMINISTRATIVO
              ]}
            >
              <MenuNavLink to="/articles">
                <MenuItemTitle icon="shopping">Artículos</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.ADMIN,
                ROLES.ASISTENTE_ADMINISTRATIVO,
                ROLES.ENCARGADO_CTA_CORRIENTE,
                ROLES.GERENTE_COMERCIAL,
                ROLES.SUPERVISOR_VENTAS
              ]}
            >
              <MenuNavLink to="/client">
                <MenuItemTitle icon="contacts">Clientes</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem key={getID()} roles={[ROLES.ADMIN, ROLES.SISTEMA]}>
              <MenuNavLink to="/rate">
                <MenuItemTitle icon="dollar">Cotización</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem key={getID()} roles={[ROLES.CONTABILIDAD]}>
              <MenuNavLink to="/import/clearance">
                <MenuItemTitle icon="global">Despachos</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[ROLES.OPERADOR_ADMINISTRATIVO, ROLES.OPERADOR_ECOMMERCE]}
            >
              <MenuNavLink to="/pricing">
                <MenuItemTitle icon="control">Configuración</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.ADMIN,
                ROLES.ENCARGADO_DEPOSITO,
                ROLES.ASISTENTE_ADMINISTRATIVO
              ]}
            >
              <MenuNavLink to="/user">
                <MenuItemTitle icon="team">Usuarios</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.ASISTENTE_ADMINISTRATIVO,
                ROLES.ADMIN,
                ROLES.OPERADOR_ADMINISTRATIVO,
                ROLES.ENCARGADO_RRHH
              ]}
            >
              <MenuNavLink to="/employee">
                <MenuItemTitle icon="apartment">Funcionarios</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[ROLES.ADMIN, ROLES.ASISTENTE_ADMINISTRATIVO]}
            >
              <MenuNavLink to="/reference">
                <MenuItemTitle icon="monitor">Referencia</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[ROLES.ADMIN, ROLES.ASISTENTE_ADMINISTRATIVO]}
            >
              <MenuNavLink to="/treasury">
                <MenuItemTitle icon={IconMoney}>Tesorería</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.ADMIN,
                ROLES.CONTABILIDAD,
                ROLES.ENCARGADO_COBRANZAS
              ]}
            >
              <MenuNavLink to="/transactions">
                <MenuItemTitle icon={<TransactionOutlined />}>
                  Transacciones
                </MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[
                ROLES.VENDEDOR_EXTERNO,
                ROLES.GERENTE_COMERCIAL,
                ROLES.GERENTE_VENTAS
              ]}
            >
              <MenuNavLink to="/uvm/proformas">
                <MenuItemTitle icon="shopping-cart">Proformas</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem key={getID()} roles={[ROLES.ENCARGADO_FACTURACION]}>
              <MenuNavLink to="/transport">
                <MenuItemTitle icon="car">Transporte</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem
              key={getID()}
              roles={[ROLES.CAJA, ROLES.ENCARGADO_COBRANZAS]}
            >
              <MenuNavLink to="/balance">
                <MenuItemTitle icon="dollar">Cajas</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>

            <ProtectedItem key={getID()} roles={[ROLES.AUDITOR]}>
              <MenuNavLink to="/audit">
                <MenuItemTitle icon="audit">Auditoría (TI)</MenuItemTitle>
              </MenuNavLink>
            </ProtectedItem>
          </ModuleAdminMenu>
        </Menu>
        <ShortcutsDrawer
          visible={shortcutsVisible}
          onClose={this.hideShortcuts}
        />
      </StyledSideMenu>
    );
  }
}

/**
 * ROLES que pueden ver el sub menú de "Administración"
 * Este menú solamente debe agrupar páginas de configuración de recursos,
 * necesarios para que otras funcionalidades puedan funcionar correctamente.
 *
 * Ejemplo: Usuarios , Parámetros.
 *
 */
function getModuleAdminRoles() {
  return [
    ROLES.ADMIN,
    ROLES.SISTEMA,
    ROLES.ENCARGADO_CTA_CORRIENTE,
    ROLES.CONTABILIDAD,
    ROLES.ENCARGADO_DEPOSITO,
    ROLES.ASISTENTE_ADMINISTRATIVO,
    ROLES.ENCARGADO_FACTURACION,
    ROLES.VENDEDOR_EXTERNO,
    ROLES.GERENTE_COMERCIAL,
    ROLES.GERENTE_VENTAS,
    ROLES.ENCARGADO_COBRANZAS,
    ROLES.ENCARGADO_MARCAS,
    ROLES.OPERADOR_ADMINISTRATIVO,
    ROLES.ENCARGADO_RRHH,
    ROLES.OPERADOR_ECOMMERCE,
    ROLES.CAJA,
    ROLES.AUDITOR,
    ROLES.SUPERVISOR_VENTAS
  ];
}
function ModuleAdminMenu({ children, ...props }) {
  const { user } = React.useContext(AuthContext);
  if (user == null) {
    return null;
  }

  if (!user.hasAnyRoleInList(getModuleAdminRoles())) {
    return null;
  }
  return (
    <SubMenu
      title={
        <span>
          <ProfileOutlined />
          <span>Administración</span>
        </span>
      }
      {...props}
    >
      {children}
    </SubMenu>
  );
}

function ProtectedItem({ roles, children, ...props }) {
  const { user } = React.useContext(AuthContext);
  if (user == null) {
    return null;
  }
  if (!user.hasAnyRoleInList(roles)) {
    return null;
  }
  return <Menu.Item {...props}>{children}</Menu.Item>;
}

function ProtectedSubMenu({ roles, children, ...props }) {
  const { user } = React.useContext(AuthContext);
  if (user == null) {
    return null;
  }
  if (!user.hasAnyRoleInList(roles)) {
    return null;
  }
  return <SubMenu {...props}>{children}</SubMenu>;
}

export default SideNav;
