// TODO Usar una libreria en lugar de esto
export const add = (firstPrice, secondPrice) => {
  const a = firstPrice * 100;
  const b = secondPrice * 100;
  return Math.round(a + b) / 100;
};

export const substract = (firstPrice, secondPrice) => {
  const a = firstPrice * 100;
  const b = secondPrice * 100;
  const result = a - b;
  return Math.round(result) / 100;
};

export const multiply = (price, quantity) => {
  const a = price * 100;
  return Math.round(a * quantity) / 100;
};

export const multiplyForOrder = (price, quantity) => {
  return multiply(price, quantity);
};

export const getOrderTotalPrice = orderItems => {
  return orderItems
    .map(({ quantity, price }) => multiplyForOrder(price, quantity))
    .reduce((totalPrice, currItem) => add(totalPrice, currItem), 0);
};

export function moneyParser(money) {
  if (!money) {
    return 0;
  }
  if (typeof money === "string") {
    const cleaned = money.replace(/\./g, "").replace(/,/g, ".");
    return parseFloat(cleaned);
  }
  return parseFloat(money);
}

export function getSafeOperand(value) {
  if (typeof value === "undefined") {
    return 0;
  }
  return value;
}

export function makeTotalFunc(key, parser = moneyParser) {
  return list =>
    list.reduce(
      (tally, object) => tally + parser(getSafeOperand(object[key])),
      0
    );
}

export function getTotalForProp(list, key) {
  return list.reduce((tally, object) => tally + object[key], 0);
}

export function calculatePercent(value, total) {
  return ((value / total) * 100).toFixed(3);
}
