import React from "react";
import { PasswordReset } from "../../components/Login";

export default function ScreensLoginPasswordReset(props) {
  return (
    <div className="login-container">
      <PasswordReset {...props} />
    </div>
  );
}
