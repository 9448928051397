import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "components/Auth/AuthenticatedRoute";
import Loading from "components/UI/Loading";
import Layout from "screens/Layout";

import axiosConfig from "api/axiosConfig";

import "./Root.scss";

const ScreensHome = React.lazy(() => import("screens/Home"));
const ScreensArticle = React.lazy(() => import("screens/Article"));

const ScreensProforma = React.lazy(() => import("screens/Order/Proforma"));
const ProformaDetail = React.lazy(() => import("screens/Order/ProformaDetail"));
const ScreensOrderList = React.lazy(() => import("screens/Order/OrderList"));
const ScreensOrderListDetail = React.lazy(() =>
  import("screens/Order/OrderListDetail")
);

const ScreensSuggestion = React.lazy(() => import("screens/Suggestion"));
const ScreensCatalogList = React.lazy(() => import("screens/Catalog/List"));
const ScreensCatalogDetail = React.lazy(() =>
  import("screens/Catalog/CatalogDetail")
);

const ScreensWarehouseInvoiceReception = React.lazy(() =>
  import("screens/Warehouse/InvoiceReception")
);

const WarehouseOrder = React.lazy(() => import("components/Warehouse/Order"));

const ScreensReentryWarehouse = React.lazy(() =>
  import("components/Warehouse/Reentry/Routes")
);

const ScreensOrderPreparationDetail = React.lazy(() =>
  import("screens/Warehouse/OrderPreparationDetail")
);
const ScreensOrderRecallDetail = React.lazy(() =>
  import("screens/Warehouse/OrderRecallDetail")
);
const ScreensClearance = React.lazy(() => import("screens/Import"));
const ScreensEntry = React.lazy(() => import("screens/Entry"));
const ScreensRate = React.lazy(() => import("screens/Rate"));
const ScreensUVMProforma = React.lazy(() => import("screens/UVM"));
const ScreensPricing = React.lazy(() => import("screens/Pricing"));
const ScreensPriceUpdatedDetails = React.lazy(() =>
  import("screens/Pricing/PriceUpdatedDetails")
);
const Transport = React.lazy(() => import("components/Transport"));
const Mifactura = React.lazy(() => import("components/Mifactura"));
const ScreensSupplier = React.lazy(() => import("screens/Supplier/Supplier"));
const ScreensSupplierForm = React.lazy(() =>
  import("screens/Supplier/SupplierForm")
);
const ScreensOrderReception = React.lazy(() =>
  import("screens/Reception/OrderReception")
);
const ImportCost = React.lazy(() => import("screens/Import/ImportCost"));
const ScreenClient = React.lazy(() => import("screens/Client"));
const ScreensUser = React.lazy(() => import("screens/User"));
const ScreensUserForm = React.lazy(() => import("screens/User/UserForm"));
const ScreensPasswordChange = React.lazy(() =>
  import("screens/Password/ChangePassword")
);

const ScreensEmployees = React.lazy(() =>
  import("components/RRHH/EmployeesRoutes")
);

const ScreenSystemParameters = React.lazy(() =>
  import("screens/System/SystemParameter")
);

const ScreensReference = React.lazy(() =>
  import("components/Reference/Routes")
);

const ScreensBalance = React.lazy(() => import("screens/Balance/Balance"));
const TransactionsPage = React.lazy(() =>
  import("components/Transactions/TransactionsPage")
);

const TreasuryRoutes = React.lazy(() => import("components/Treasury/Routes"));

const ScreensVoucher = React.lazy(() => import("screens/Voucher"));
const ReturnRequestRoutes = React.lazy(() =>
  import("components/ReturnRequest/Routes")
);
const ScreensError = React.lazy(() => import("screens/Error/Error"));
const ScreensNotFound = React.lazy(() => import("screens/Error/NotFound"));
const ScreensForbidden = React.lazy(() => import("screens/Error/Forbidden"));
const ScreensMovement = React.lazy(() => import("screens/Movement"));
const ScreensEcommerce = React.lazy(() =>
  import("screens/Ecommerce/Ecommerce")
);
const ScreensAudit = React.lazy(() => import("screens/Audit/Audit"));
const AccountancyRoutes = React.lazy(() =>
  import("components/Accountancy/AccountancyRoutes")
);
const ScreensGratification = React.lazy(() =>
  import("components/Gratification/GratificationRoutes")
);
axiosConfig.setupTokenInterceptor();

function ScreensRoot() {
  return (
    <Layout>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>

          <AuthenticatedRoute path="/home" component={ScreensHome} />

          <AuthenticatedRoute path="/articles" component={ScreensArticle} />

          <AuthenticatedRoute
            path="/suggestions"
            component={ScreensSuggestion}
          />

          <AuthenticatedRoute
            exact
            path="/proformas"
            component={ScreensProforma}
          />

          <AuthenticatedRoute
            exact
            path="/proformas/detail"
            component={ProformaDetail}
          />

          <AuthenticatedRoute
            exact
            path="/orders/list"
            component={ScreensOrderList}
          />
          <AuthenticatedRoute
            exact
            path="/orders/list/detail"
            component={ScreensOrderListDetail}
          />
          <AuthenticatedRoute
            exact
            path="/orders/reception"
            component={ScreensOrderReception}
          />
          <AuthenticatedRoute
            exact
            path="/supplier"
            component={ScreensSupplier}
          />

          <AuthenticatedRoute
            exact
            path="/supplier/form"
            component={ScreensSupplierForm}
          />
          <AuthenticatedRoute
            exact
            path="/catalog"
            component={ScreensCatalogList}
          />
          <AuthenticatedRoute
            path="/catalog/articles"
            component={ScreensCatalogDetail}
          />
          <AuthenticatedRoute
            exact
            path="/warehouse"
            component={ScreensWarehouseInvoiceReception}
          />
          <AuthenticatedRoute
            path="/warehouse/order"
            component={WarehouseOrder}
          />

          <AuthenticatedRoute
            path="/warehouse/reentry"
            component={ScreensReentryWarehouse}
          />

          <AuthenticatedRoute
            exact
            path="/order/preparation/detail"
            component={ScreensOrderPreparationDetail}
          />
          <AuthenticatedRoute
            exact
            path="/order/recall/detail"
            component={ScreensOrderRecallDetail}
          />
          <AuthenticatedRoute
            exact
            path="/import/clearance"
            component={ScreensClearance}
          />
          <AuthenticatedRoute
            exact
            path="/import/cost"
            component={ImportCost}
          />

          <AuthenticatedRoute exact path="/entrance" component={ScreensEntry} />
          <AuthenticatedRoute exact path="/rate" component={ScreensRate} />

          <AuthenticatedRoute
            exact
            path="/pricing"
            component={ScreensPricing}
          />

          <AuthenticatedRoute
            exact
            path="/pricing/updatedDetails"
            component={ScreensPriceUpdatedDetails}
          />
          <AuthenticatedRoute exact path="/mifactura" component={Mifactura} />
          <AuthenticatedRoute exact path="/user" component={ScreensUser} />
          <AuthenticatedRoute
            exact
            path="/user/form"
            component={ScreensUserForm}
          />
          <AuthenticatedRoute path="/client" component={ScreenClient} />

          <AuthenticatedRoute path="/transport" component={Transport} />

          <AuthenticatedRoute path="/uvm" component={ScreensUVMProforma} />

          <AuthenticatedRoute
            exact
            path="/password/change"
            component={ScreensPasswordChange}
          />

          <AuthenticatedRoute path="/employee" component={ScreensEmployees} />

          <AuthenticatedRoute
            path="/parameter"
            component={ScreenSystemParameters}
          />

          <AuthenticatedRoute path="/reference" component={ScreensReference} />

          <AuthenticatedRoute path="/treasury" component={TreasuryRoutes} />

          <AuthenticatedRoute path="/balance" component={ScreensBalance} />

          <AuthenticatedRoute
            path="/transactions"
            component={TransactionsPage}
          />

          <AuthenticatedRoute path="/voucher" component={ScreensVoucher} />

          <AuthenticatedRoute path="/return" component={ReturnRequestRoutes} />

          <AuthenticatedRoute path="/movement" component={ScreensMovement} />

          <AuthenticatedRoute path="/ecommerce" component={ScreensEcommerce} />

          <AuthenticatedRoute path="/audit" component={ScreensAudit} />

          <AuthenticatedRoute
            path="/accountancy/tabs"
            component={AccountancyRoutes}
          />

          <AuthenticatedRoute
            path="/gratification"
            component={ScreensGratification}
          />

          <Route path="/error" component={ScreensError} />
          <Route path="/notfound404" component={ScreensNotFound} />
          <Route path="/forbidden403" component={ScreensForbidden} />
        </Switch>
      </React.Suspense>
    </Layout>
  );
}

export default ScreensRoot;
