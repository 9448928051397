import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { NavLink, withRouter } from "react-router-dom";

import logo from "images/logo_transparent.png";

import { AuthContext } from "components/Auth/useAuth";

import Profile from "./Profile";
import "./Header.scss";

const HeaderWrapper = styled.nav`
  max-height: 3.6rem;

  a {
    line-height: 1.5;
    position: relative;
    display: block;
    img {
      height: 100%;
      width: auto;
    }
  }

  a.logo-link {
    padding: 0.5rem 1rem;
  }

  div.main-search > input {
    background: #f5f5f5;
  }

  .icons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      padding: 0 1rem;
    }
  }

  .trigger:hover {
    color: #1890ff;
  }
`;
const HeaderIcon = ({ type }) => (
  <LegacyIcon type={type} style={{ fontSize: 22, paddingTop: "50%" }} />
);

function Header(props) {
  const { logout } = React.useContext(AuthContext);
  const { user } = props;

  const toggleSideNav = e => {
    e.preventDefault();
    props.toggleSideNav();
  };

  const toggleShortcuts = e => {
    e.preventDefault();
    props.toggleShortcuts();
  };

  const onLogout = async e => {
    e.preventDefault();
    try {
      await logout();
    } finally {
      props.history.push({ pathname: "/login" });
    }
  };

  const showChangePassword = () => {
    props.history.push({ pathname: "/password/change" });
  };

  return (
    <div>
      <HeaderWrapper
        className="navbar lin-navbar has-shadow"
        aria-label="main navigation"
      >
        <div className="navbar-brand is-boxed">
          <div className="menu-trigger is-vertical-center">
            <LegacyIcon
              className="trigger"
              type={props.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={toggleSideNav}
            />
          </div>
          <a className="logo-link" href="/">
            <img src={logo} alt="logo" className="logo" />
          </a>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="icons">
              <NavLink
                to="/home"
                className="tooltip is-tooltip-dark is-tooltip-bottom"
                data-tooltip="Inicio"
              >
                <HeaderIcon type="home" />
              </NavLink>
              {user && user.hasAnyRole("ADMIN") && (
                <NavLink
                  to="/suggestions"
                  className="tooltip is-tooltip-dark is-tooltip-bottom"
                  data-tooltip="Sugerencias"
                >
                  <HeaderIcon type="carry-out" />
                </NavLink>
              )}
              <a
                href="/#"
                className="tooltip is-tooltip-dark is-tooltip-bottom"
                data-tooltip="Atajos de Teclado"
                onClick={toggleShortcuts}
              >
                <HeaderIcon type="laptop" />
              </a>
              {user !== null ? (
                <Profile
                  user={user}
                  logout={onLogout}
                  onChangePassword={showChangePassword}
                />
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    props.history.push({ pathname: "/login" });
                  }}
                >
                  Salir
                </Button>
              )}
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </div>
  );
}

export default withRouter(Header);
