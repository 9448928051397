import React from "react";
import authService from "api/authService";
import { getUserInfo } from "api/userApi";
import {
  removeAccessToken,
  setTokens,
  buildUser,
  isAuthenticated
} from "./auth";

export const AuthContext = React.createContext({
  user: null,
  isAuthenticated: () => false
});

// Provee a componentes de la app con información
// de authenticación y usuario logueado
// Se provee mediante un context
export function AuthProvider({ children }) {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Almacena en su estado información
// de autenticación y del perfil del usuario logueado
// Expone las siguientes propiedades:
// 1. user : usuario authenticado y su información
// 2. isAuthenticated: función que permite saber si hay un token
// 3. login: funcion que permite hacer login
// 4. logout: función que permite hace logout
// 5. resetStateOnLogout: para casos de expiración de token, que se pueda resetear
// el estado interno desde afuera
export function useAuth() {
  // null: first mount
  // false: logged out
  // object: logged in
  const [user, setUser] = React.useState(null);

  const isAuthenticationError = error => {
    if (error.response) {
      const { response } = error;
      if (response.status === 401) {
        return true;
      }
    }
    return false;
  };

  const login = async cred => {
    const authResult = await authService.authenticate(cred);

    const { secret: accessToken } = authResult.data;
    setTokens({ accessToken });

    const userInfoResult = await getUserInfo();
    setUser(buildUser(userInfoResult.dto));

    return { authResult, userInfoResult };
  };

  const logout = async () => {
    return authService
      .signout()
      .then(() => {
        resetStateOnLogout();
      })
      .catch(error => {
        console.error({ error });
        if (isAuthenticationError(error)) {
          resetStateOnLogout();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      });
  };

  const resetStateOnLogout = () => {
    removeAccessToken();
    // This will force an update to all components
    // that uses AuthContext
    setUser(false);
  };

  React.useEffect(() => {
    if (isAuthenticated() && user === null) {
      getUserInfo()
        .then(result => setUser(buildUser(result.dto)))
        .catch(error => {
          if (isAuthenticationError(error)) {
            resetStateOnLogout();
          }
        });
    }
  }, [user]);

  return { user, login, logout, isAuthenticated, resetStateOnLogout };
}
